/**
 * Order is very important.
 * 1st: SCSS variable overrides
 * 2nd: build import
 * 3rd: component hooks
 * 4th: component import
 * 5th: CSS variables declaration/override (build includes :root level declaration)
 * 6th: other custom CSS
 */

//@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// rubic font for admin

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61E_a5IlWzvOU.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61E_a5IlWzvOU.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661E_a5IlWzg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61E_a5IlWzvOU.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61E_a5IlWzvOU.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661E_a5IlWzg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61E_a5IlWzvOU.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61E_a5IlWzvOU.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661E_a5IlWzg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61E_a5IlWzvOU.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61E_a5IlWzvOU.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661E_a5IlWzg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61E_a5IlWzvOU.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61E_a5IlWzvOU.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661E_a5IlWzg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61E_a5IlWzvOU.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61E_a5IlWzvOU.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661E_a5IlWzg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61E_a5IlWzvOU.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61E_a5IlWzvOU.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661E_a5IlWzg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyw1W3fxIlGzg.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyw1W3fxIlGzg.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXyw1W3fxIk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyw1W3fxIlGzg.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyw1W3fxIlGzg.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXyw1W3fxIk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyw1W3fxIlGzg.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyw1W3fxIlGzg.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXyw1W3fxIk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyw1W3fxIlGzg.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyw1W3fxIlGzg.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXyw1W3fxIk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyw1W3fxIlGzg.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyw1W3fxIlGzg.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXyw1W3fxIk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyw1W3fxIlGzg.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyw1W3fxIlGzg.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyw1W3fxIlGzg.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXyw1W3fxIk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// end rubic. begin public sans

//@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJLax9k0.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJzaxw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJLax9k0.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJzaxw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJLax9k0.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJzaxw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJLax9k0.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJzaxw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJLax9k0.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJzaxw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJLax9k0.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJzaxw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJLax9k0.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJzaxw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJLax9k0.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJzaxw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJLax9k0.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDTJzaxw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNIfJ7Cww.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNGfJ4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNIfJ7Cww.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNGfJ4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNIfJ7Cww.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNGfJ4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNIfJ7Cww.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNGfJ4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNIfJ7Cww.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNGfJ4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNIfJ7Cww.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNGfJ4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNIfJ7Cww.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNGfJ4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNIfJ7Cww.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNGfJ4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNIfJ7Cww.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwnNGfJ4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


//@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Hebrew:wght@100;200;300;400;500;600;700&display=swap");
// IBM plex
/* hebrew */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa4qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEXBylgfqQaM.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa4qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEXBylQfqQaM.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa4qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEXBylofqQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEVt2006jDu19Q.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEVt2002jDu19Q.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEVt2004jDs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEUJ2E06jDu19Q.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEUJ2E02jDu19Q.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEUJ2E04jDs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa2qYENg9Kw1mpLpO0bGM5lfHAAZHhDXE2g-lgHrQ.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa2qYENg9Kw1mpLpO0bGM5lfHAAZHhDXE2s-lgHrQ.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa2qYENg9Kw1mpLpO0bGM5lfHAAZHhDXE2i-lg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEVR2U06jDu19Q.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEVR2U02jDu19Q.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEVR2U04jDs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEV93k06jDu19Q.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEV93k02jDu19Q.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEV93k04jDs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEUZ3006jDu19Q.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEUZ3002jDu19Q.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEUZ3004jDs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// 1st: SCSS variable overrides
$global-prefix: "";

// 2nd: build import
@import "../../../../../../otherSrc/client/we/scss/build";

// our custom component // * NOTE: we can not include partial SCSS files inside the client forlder, atm.

// 3rd: component hooks
/*
@mixin hook-button {
  border-radius: var(--border-radius);
  &--facebook {
    @include _button-color(
      var(--c-facebook),
      var(--c-bg),
      var(--c-bg),
      var(--c-facebook),
      true
    );
    border: 2px solid var(--c-facebook);
  }
}

@mixin hook-form-field-element {
  background-color: var(--c-bg);
  border-radius: var(--border-radius);
}

@mixin hook-form-form-control {
  border-radius: var(--border-radius);
}

@mixin hook-field {
  border-radius: var(--border-radius);

  input {
    border-radius: var(--border-radius);
    background-color: var(--c-bg);
  }
}

@mixin hook-form-field-error-message {
  margin-top: calc(var(--margin-s) * 0.75);
  padding-inline-start: 10px;
}

@mixin hook-modal {
    &__inner {
        border-radius: var(--border-radius-m);
    }
}

@mixin hook-alert {
    a:not(button) {
        color: var(--c-bg-1);
        text-decoration: underline;
    }
}

@mixin hook-panel {
  border-radius: var(--border-radius);
}

@mixin hook-form-field-placeholder {
  opacity: 0.8;
  color: var(--c-gray-5);
}

@mixin hook-notification {
  background-color: var(--c-bg-2);
}

@mixin hook-tabs {
  &__control {
    color: var(--c-inverse);

    &.active {
      color: var(--c-lead);
    }

    &.active:hover {
      color: var(--c-lead);
    }

    &:not(.active):hover {
      color: var(--c-inverse);
    }

  }
}
*/
// 4th: component import
@import "../../../../../../otherSrc/client/we/scss/components";

// 5th: CSS variables declaration/override
// admin
:root {
  /*
  --c-core-lead-1: #539cd4;
  --c-core-lead-2: #368ccd;
  --c-core-lead: #197bc6;
  --c-core-lead-4: #267cbd;
  --c-core-lead-5: #337cb4;

  --c-core-alt-1: #dc5eb7;
  --c-core-alt-2: #d334a4;
  --c-core-alt: #b22b8a;
  --c-core-alt-3: #b22b8a;
  --c-core-alt-4: #a02b7e;
  --c-core-alt-5: #50153f;

  --c-core-bg-1: #fffeff;
  --c-core-bg-2: #fffbfa;
  --c-core-bg: #ffffff;
  --c-core-bg-4: #efe2cf;
  --c-core-bg-5: #ebdecb;

  --c-core-inverse: #171c27;
  --c-text: #1c1b1b; // design uses purple and gray

  --c-comp-lead-1: var(--c-success-1);
  --c-comp-lead-2: var(--c-success-2);
  --c-comp-lead: var(--c-success);
  --c-comp-lead-3: var(--c-success);
  --c-comp-lead-4: #8e103e;
  --c-comp-lead-5: var(--c-success-5);

  --c-comp-alt-1: #fbbe47;
  --c-comp-alt-2: #f69b30;
  --c-comp-alt: var(
    --c-warning
  ); // this is the original color. it has poor contrast
  --c-comp-alt-4: #b86808;
  --c-comp-alt-5: #121212;

  --c-gray-1: #f8f6f4;
  --c-gray-2: #e3e1df;
  --c-gray: #c6c5c4;
  --c-gray-4: #aaa9a9;
  --c-gray-5: #747474;

  --c-success: #2b9a3b;
  --c-success-inverse: var(--c-bg-1);
  --c-warning: #e9830a; // darkened compt alt, not in scheme
  --c-warning-inverse: var(--c-bg-1);
  --c-danger: #ba2e07;
  --c-danger-inverse: var(--c-bg);
  --c-error: var(--c-danger);
  --c-error-inverse: var(--c-danger-inverse);

  --margin-xs: calc(2 * var(--base-margin));
  --margin-s: calc(5 * var(--base-margin));
  --margin-m: calc(12 * var(--base-margin));
  --margin: var(--margin-m);
  --margin-l: calc(24 * var(--base-margin));
  --margin-xl: calc(38 * var(--base-margin));
  --margin-2xl: calc(42 * var(--base-margin));
  --margin-max: calc(76 * var(--base-margin));

  --border-radius: calc(12 * var(--base-margin));
  font-size: var(--font-m);

   */
}

// props for gradiant buttons
@property --c-bg-gradiant-lead-1 {
  syntax: "<color>";
  initial-value: white;
  inherits: false;
}

@property --c-bg-gradiant-lead-2 {
  syntax: "<color>";
  initial-value: white;
  inherits: false;
}

@property --c-bg-gradiant-alt-1 {
  syntax: "<color>";
  initial-value: white;
  inherits: false;
}

@property --c-bg-gradiant-alt-2 {
  syntax: "<color>";
  initial-value: white;
  inherits: false;
}

@property --c-bg-gradiant-alt-4-1 {
  syntax: "<color>";
  initial-value: white;
  inherits: false;
}

@property --c-bg-gradiant-alt-4-2 {
  syntax: "<color>";
  initial-value: white;
  inherits: false;
}

// client
body.app-page {
  --base-margin: calc(100vw / 1920);
  --base-font-size: calc(100vw / 1920);

  @media (max-width: 1024px) {
    --base-margin: 1px;
    --base-font-size: 1px;
  }
  font-size: var(--font-m);

  * {
    // 100vw = 2000 -> 1
    // 100vw = 1000 -> 0.5

    --base-margin: calc(100vw / 1920);
    --base-font-size: calc(100vw / 1920);

    --container-gutter: calc(32 * var(--base-margin));
    --container-l-width-seed: calc(1502 * var(--base-margin));
    --container-l: calc(
      var(--container-l-width-seed) + (2 * var(--container-gutter))
    );
    @media (max-width: 1024px) {
      & {
        --base-margin: 1px;
        --base-font-size: 1px;
        --container-gutter: calc(24 * var(--base-margin));
      }
    }

    // color tests
    //backgrounds 3-5
    --test1: #ffffff;
    --test2: #f7f7f9;
    --test3: #f4f4f6;

    // f3f3f3
    --c-gray-1: #f4f4f6;
    --c-gray-2: #dadada;

    --c-gray: #a7a7a7;
    --c-gray-3: #a7a7a7;
    --c-gray-4: #747474;
    --c-gray-5: #5a5a5a;

    --c-inverse: #1f2041;
    --c-text: #0e1625;

    // uncommon - for card gradiants only
    --c-purple-gradiant-1: #cc96ff;
    --c-purple-gradiant-2: rgba(167, 76, 242, 0.8);

    --c-blue-gradiant-1: #7eb2ed;
    --c-blue-gradiant-2: #436fc6;

    --c-yellow-gradiant-1: #ffbb6f;
    --c-yellow-gradiant-2: #ffbf45;

    // lead

    --c-core-lead-1: #755484;
    --c-core-lead-2: #9520d3;
    --c-core-lead: #310047;
    --c-core-lead-3: #310047;
    --c-core-lead-4: #210030;
    --c-core-lead-5: #13001c;

    --c-lead-1: var(--c-core-lead-1);
    --c-lead-2: var(--c-core-lead-2);
    --c-lead: var(--c-core-lead);
    --c-lead-3: var(--c-core-lead-3);
    --c-lead-4: var(--c-core-lead-4);
    --c-lead-5: var(--c-core-lead-5);

    // alt
    --c-core-alt-0: #ffbf45;
    --c-core-alt-1: #ffad89;
    --c-core-alt-2: #fc8957;
    --c-core-alt: #ff850b;
    --c-core-alt-3: #ff850b;
    --c-core-alt-4: #d76c00;
    --c-core-alt-5: #a45200;

    --c-alt-1: var(--c-core-alt-1);
    --c-alt-2: var(--c-core-alt-2);
    --c-alt: var(--c-core-alt);
    --c-alt-3: var(--c-core-alt-3);
    --c-alt-4: var(--c-core-alt-4);
    --c-alt-5: var(--c-core-alt-5);

    --c-comp-alt-1: #fbbe47;
    --c-comp-alt-2: #f69b30;
    --c-comp-alt: var(
                    --c-warning
    ); // this is the original color. it has poor contrast
    --c-comp-alt-4: #b86808;
    --c-comp-alt-5: #121212;


    --c-comp-lead-1: #d885af;
    --c-comp-lead-2: #ff89c8;
    --c-comp-lead: #d90953;
    --c-comp-lead-3: #d90953;
    --c-comp-lead-4: #9f1245;

    --c-comp-lead-5: #46052e;

    // margins
    --margin-xs: calc(2 * var(--base-margin));
    --margin-s: calc(5 * var(--base-margin));
    --margin-m: calc(12 * var(--base-margin));
    --margin: var(--margin-m);
    --margin-l: calc(24 * var(--base-margin));
    --margin-xl: calc(38 * var(--base-margin));
    --margin-2xl: calc(48 * var(--base-margin));
    --margin-max: calc(64 * var(--base-margin));

    // fonts
    --font-min: calc(12 * var(--base-font-size));
    --font-xs: calc(14 * var(--base-font-size));
    --font-s: calc(16 * var(--base-font-size));
    --font-m: calc(18 * var(--base-font-size));
    --font-l: calc(20 * var(--base-font-size));
    --font-xl: calc(24 * var(--base-font-size));
    --font-2xl: calc(32 * var(--base-font-size));
    --font-3xl: calc(40 * var(--base-font-size));
    --font-jumbo: calc(48 * var(--base-font-size));
    --font-max: calc(56 * var(--base-font-size));
  }

  //28 // only once on h2 mobile
  // 64 // only once on H1

  h1,
  .h1,
  .text-max {
    font-size: calc(var(--font-max) * 1.17); // thats 64 originally
    font-weight: 700;

    @media (max-width: 1024px) {
      font-size: var(--font-2xl);
    }
  }

  h2,
  .h2,
  .text-jumbo {
    font-size: calc(var(--font-max));
    font-weight: 700;

    @media (max-width: 1024px) {
      font-size: calc(var(--font-xl) * 1.17); // thats 28 originally
    }
  }

  h3,
  .h3,
  .text-3xl {
    font-size: calc(var(--font-jumbo));
    font-weight: 700;

    @media (max-width: 1024px) {
      font-size: var(--font-xl); // thats 28 originally
      font-weight: 600;
    }
  }

  h4,
  .h4,
  .text-2xl {
    font-size: calc(var(--font-3xl));
    font-weight: 700;

    @media (max-width: 1024px) {
      font-size: var(--font-xl); // thats 28 originally
      font-weight: 700;
    }
  }

  .text-button {
    font-size: var(--font-2xl);
    font-weight: 700;

    @media (max-width: 1024px) {
      font-size: var(--font-xl);
      font-weight: 400;
    }
  }

  .text-sub-title,
  .text-subtitle {
    font-size: var(--font-2xl);
    font-weight: 400;

    @media (max-width: 1024px) {
      font-size: var(--font-s);
      font-weight: 400;
    }
  }

  .text-xl,
  .text-body,
  .text-body-1 {
    font-size: var(--font-xl);
    font-weight: 400;

    @media (max-width: 1024px) {
      font-size: var(--font-xs);
      font-weight: 400;
    }
  }

  .text-l,
  .text-body-2 {
    font-size: var(--font-l);
    font-weight: 400;

    @media (max-width: 1024px) {
      font-size: var(--font-min);
      font-weight: 400;
    }
  }

  .text-m,
  .text-body-3 {
    font-size: var(--font-m);
    font-weight: 400;

    @media (max-width: 1024px) {
      font-size: var(--font-xs);
      font-weight: 400;
    }
  }

  @mixin bg-gradiant-lead {
    --c-bg-gradiant-lead-1: var(--c-core-lead);
    --c-bg-gradiant-lead-2: var(--c-comp-lead-4);
    background: linear-gradient(
      269deg,
      var(--c-bg-gradiant-lead-1) 13.07%,
      var(--c-bg-gradiant-lead-2) 87.75%
    ) !important;

    &.hover-effect {
      transition: --c-bg-gradiant-lead-1 1s, --c-bg-gradiant-lead-2 1s;
    }

    &.hover-effect:hover {
      --c-bg-gradiant-lead-1: var(--c-comp-lead-4);
      --c-bg-gradiant-lead-2: var(--c-core-lead);
    }
  }

  @mixin bg-gradiant-lead-with-hover {
    --c-bg-gradiant-lead-1: var(--c-core-lead);
    --c-bg-gradiant-lead-2: var(--c-comp-lead-4);
    background: linear-gradient(
      269deg,
      var(--c-bg-gradiant-lead-1) 13.07%,
      var(--c-bg-gradiant-lead-2) 87.75%
    ) !important;

    & {
      transition: --c-bg-gradiant-lead-1 1s, --c-bg-gradiant-lead-2 1s;
    }

    &:hover {
      --c-bg-gradiant-lead-1: var(--c-comp-lead-4);
      --c-bg-gradiant-lead-2: var(--c-core-lead);
    }
  }

  // gradiants
  .bg-gradiant,
  .bg-gradiant-lead {
    @include bg-gradiant-lead;
  }

  .bg-category-gradiant-1 {
    background: linear-gradient(314deg, #ef7802 43.39%, #ffad89 100%);
  }

  .bg-category-gradiant-2 {
    background: linear-gradient(314deg, #9520d3 37.93%, #cc96ff 100%);
  }

  .bg-category-gradiant-3 {
    background: linear-gradient(314deg, #9f1245 43.36%, #d885af 100%);
  }

  .bg-category-gradiant-4 {
    background: linear-gradient(314deg, #d90953 38.17%, #ff89c8 100%);
  }

  .bg-category-gradiant-5 {
    background: linear-gradient(
      314deg,
      #436fc6 30.71%,
      #9ec0e6 100%,
      #7eb2ed 100%
    );
  }

  .bg-category-gradiant-6 {
    background: linear-gradient(314deg, #fba604 48.08%, #ffbb6f 100%);
  }

  .bg-gradiant-alt {
    --c-bg-gradiant-alt-1: var(--c-yellow-gradiant-2);
    --c-bg-gradiant-alt-2: var(--c-core-alt);
    background: linear-gradient(
      112deg,
      var(--c-bg-gradiant-alt-1) 11.25%,
      var(--c-bg-gradiant-alt-2) 39.22%
    );

    &.hover-effect {
      transition: --c-bg-gradiant-alt-1 1s, --c-bg-gradiant-alt-2 1s;
    }

    &.hover-effect:hover {
      --c-bg-gradiant-alt-1: var(--c-core-alt);
      --c-bg-gradiant-alt-2: var(--c-yellow-gradiant-2);
    }
  }

  .bg-gradiant-alt-4 {
    --c-bg-gradiant-alt-4-1: var(--c-core-alt-2);
    --c-bg-gradiant-alt-4-2: var(--c-core-alt-3);

    background: linear-gradient(
      312deg,
      var(--c-bg-gradiant-alt-4-1) 25.41%,
      var(--c-bg-gradiant-alt-4-2) 96.54%
    );

    &.hover-effect {
      transition: --c-bg-gradiant-alt-1 1s, --c-bg-gradiant-alt-2 1s;
    }

    &.hover-effect:hover {
      --c-bg-gradiant-alt-4-1: var(--c-core-alt-3);
      --c-bg-gradiant-alt-4-2: var(--c-core-alt-2);
    }
  }

  .date-picker--lead {
    .dp__menu,
    .dp__theme_light {
      --dp-background-color: transparent;
      --dp-text-color: var(--c-bg);
      --dp-hover-color: var(--c-bg-4);
      --dp-hover-text-color: var(--c-gray-5);
      --dp-hover-icon-color: var(--c-gray-5);
      --dp-primary-color: var(--c-alt);
      --dp-primary-disabled-color: var(--c-lead-2);
      --dp-primary-text-color: var(--c-bg-4);
      --dp-secondary-color: var(--c-gray-3);
      --dp-border-color: transparent;
      --dp-menu-border-color: transparent;
      --dp-border-color-hover: var(--c-gray-4);
      --dp-disabled-color: var(--c-gray-2);
      --dp-scroll-bar-background: var(--c-gray-2);
      --dp-scroll-bar-color: var(--c-lead);
      --dp-success-color: var(--c-success);
      --dp-success-color-disabled: var(--c-success-2);
      --dp-icon-color: var(--c-gray-4);
      --dp-danger-color: var(--c-warning);
      --dp-marker-color: var(--c-warning);
      --dp-tooltip-color: var(--c-bg-4);
      --dp-disabled-color-text: var(--c-gray-4);
    }

    .dp__outer_menu_wrap {
      border: 2px solid #9f1245;
      border-radius: var(--border-radius-l);
      @include bg-gradiant-lead;
    }

    .dp__menu {
      background: transparent !important;
    }

    .dp__instance_calendar {
      border: none !important;
    }

    .dp__menu_inner {
      background: transparent !important;
      border: none !important;
    }

    .dp__calendar_header_item {
      color: var(--c-gray-1);
      font-size: var(--font-s);
    }
  }

  // there is a special gradiant for the buttons as well....

  @mixin gradiant-lead-button {
    --c-bg-gradiant-lead-button-1: var(--c-comp-lead-4);
    --c-bg-gradiant-lead-button-2: var(--c-core-lead);
    border-radius: calc(var(--base-margin) * 30);
    border: 1px solid #ff53c0;
    background: linear-gradient(
      115deg,
      var(--c-bg-gradiant-lead-button-1) 15.07%,
      var(--c-bg-gradiant-lead-button-2) 82.24%
    );
    & {
      transition: all 1s, --c-bg-gradiant-lead-button-1 1s,
        --c-bg-gradiant-lead-button-2 1s;
    }

    &:hover,
    &.hover {
      border: 1px solid #ffdef3;
      box-shadow: 0 calc(var(--base-margin) * 12) calc(var(--base-margin) * 26)
        0px rgba(0, 0, 0, 0.5);
      color: var(--c-bg);
    }
    &.active,
    &:hover:active,
    &.focus {
      border: 1px solid #ffdef3;
      --c-bg-gradiant-lead-button-1: var(--c-core-lead);
      --c-bg-gradiant-lead-button-2: var(--c-core-lead);
      box-shadow: none;
    }

    &.disabled,
    &[disabled] {
      border-color: var(--c-gray-3) !important;
      background: var(--c-gray-3) !important;
      opacity: 0.9;
    }
  }

  @mixin gradiant-alt-button {
    --c-bg-gradiant-alt-button-1: var(--c-yellow-gradiant-2);
    --c-bg-gradiant-alt-button-2: var(--c-core-alt);
    border-radius: calc(var(--base-margin) * 30);

    background: linear-gradient(
      235deg,
      var(--c-bg-gradiant-alt-button-1) 27.3%,
      var(--c-bg-gradiant-alt-button-2) 73.08%
    );

    // backdrop-filter: blur(22.112014770507812px);

    &.box-shadow {
      box-shadow: 0px calc(var(--base-margin) * 13)
        calc(var(--base-margin) * 26) 0px var(--c-comp-lead-5);
    }

    & {
      transition: all 1s, --c-bg-gradiant-alt-button-1 1s,
        --c-bg-gradiant-alt-button-2 1s;
    }

    &:hover,
    &.hover {
      background-color: var(--c-bg);
      border-color: var(--c-bg);
      color: var(--c-bg);
      // backdrop-filter: blur(22.112014770507812px);
    }

    &.active,
    &:hover:active,
    &.focus {
      border-color: var(--c-bg);
      --c-bg-gradiant-alt-button-1: var(--c-core-alt);
      --c-bg-gradiant-alt-button-2: var(--c-core-alt);
      color: var(--c-bg);
    }

    &.disabled,
    &[disabled=""] {
      border-color: var(--c-gray-4) !important;
      background: var(--c-gray-4) !important;
      opacity: 0.9;
    }
  }

  .button,
  .button--medium {
    border-radius: 1em;
    border-width: 1px;
    border-style: solid;
    height: calc(var(--base-margin) * 70);
    min-height: calc(var(--base-margin) * 54) !important;
    font-size: calc(var(--font-2xl) * 0.85);
    font-weight: 500;
    // mobile size

    @include whenSmallerThan(l) {
      height: calc(var(--base-margin) * 54) !important;
      font-size: var(--font-xl);
      font-weight: 500;
      min-height: calc(var(--base-margin) * 54) !important;
    }
    &.button--large,
    &.button--l {
      height: calc(var(--base-margin) * 90) !important;
      font-size: var(--font-2xl);
      font-weight: bold;
    }

    &.button--small,
    &.button--s {
      height: calc(var(--base-margin) * 50) !important;
      min-height: calc(var(--base-margin) * 40) !important;
      font-size: var(--font-xl);
      font-weight: bold;

      @include whenSmallerThan(l) {
        height: calc(var(--base-margin) * 40) !important;
        min-height: calc(var(--base-margin) * 40) !important;
        font-size: var(--font-l);
        padding-inline: 0.75em !important;
        font-weight: bold;
      }
    }

    &.button--extra-small,
    &.button--xs {
      height: calc(var(--base-margin) * 38) !important;
      min-height: calc(var(--base-margin) * 28) !important;
      font-size: var(--font-m);
      font-weight: bold;

      @include whenSmallerThan(l) {
        height: calc(var(--base-margin) * 30) !important;
        min-height: calc(var(--base-margin) * 25) !important;
        font-size: var(--font-m);
        font-weight: bold;
      }
    }
    &.button--micro,
    &.button--minimum-size,
    &.button--min {
      height: calc(var(--base-margin) * 30) !important;
      min-height: calc(var(--base-margin) * 25) !important;
      font-size: var(--font-m);
      font-weight: bold;

      @include whenSmallerThan(l) {
        height: calc(var(--base-margin) * 30) !important;
        min-height: calc(var(--base-margin) * 25) !important;
        font-size: var(--font-m);
        font-weight: bold;
      }
    }

    //hover-effect bg-gradiant-lead

    &--lead {
      //border-color: var(--c-lead);

      @include gradiant-lead-button();
    }

    &--alt {
      //border-color: var(--c-lead);

      @include gradiant-alt-button();
    }

    &--lead-inverse {
      border-width: calc(var(--base-margin) * 3);

      &:hover,
      &.hover {
        background: rgba(149, 32, 211, 0.04);
        color: var(--c-lead);
      }

      &:active,
      &.active {
        background: rgba(149, 32, 211, 0.14);
        color: var(--c-lead);
      }

      &.disabled,
      &[disabled=""] {
        background: var(--c-bg) !important;
        opacity: 0.9;
        color: var(--c-gray-4);
        border-color: var(--c-gray-4);
      }
    }
  }

  // form

  .form .fields {
    margin-bottom: calc(var(--margin-xl));

    &.margin-remove {
      margin-bottom: 0 !important;
    }
    .field + .field {
      margin-top: var(--margin-l);
    }
  }

  .field__controls {
    min-height: calc(60 * var(--base-margin));
    padding: 0;
    border-radius: var(--margin-xl);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    &--disabled {
      border-color: var(--c-gray-2) !important;
      background: var(--c-gray-2) !important;
    }

    &--s {
      min-height: calc(40 * var(--base-margin));
      border-radius: var(--border-radius-m);
      .field__element {
        padding-inline: var(--margin-m);
        font-size: var(--font-s);
        color: var(--c-inverse);
        border-radius: var(--border-radius-s);
      }
    }

    @include whenSmallerThan(l) {
      border-radius: var(--margin-l);
    }

    &--inline {
      flex-direction: row;
      justify-content: start;
    }

    &--radio {
      min-height: 0;
    }

    &--textArea,
    &--textarea.field__controls--inline {
      flex-direction: column;
      padding-block: var(--margin-m);
      padding-inline: 0;
      min-height: calc(100 * var(--base-margin));

      textarea {
        min-height: 0;
        max-width: calc(100% - var(--margin-l));
      }

      .character-counter {
        width: 100%;
      }
    }

    &--select2,
    &--mappedSelect2 {
      .field__element {
        display: flex;
        align-items: center;
      }

      .pseudo-placeholder {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    &--checkbox {
      height: auto;
      display: block;
    }

    &--artistsMedia {
      min-height: 0;
      padding: 0;
      border-radius: 0;
      display: block;
      border: none;
    }

    &--artistsMediaUrl {
      min-height: 0;
      padding: 0;
      border-radius: 0;
      display: block;
      border: none;
    }

    &--editor {
      .editor-wrapper {
        > div {
          width: 100%;
        }
      }
    }

    &--customFormHtml {
      min-height: 0;
      padding: 0;
      border-radius: 0;
      display: block;
      border: none;
    }

    &--radio {
      height: auto;
    }
  }

  .field__element {
    padding: var(--margin-s) var(--margin-xl) var(--margin-s) var(--margin-xl);
    height: auto;
    font-size: var(--font-l);
    border-radius: var(--border-radius-l);

    @include whenSmallerThan(l) {
      font-size: var(--font-m);
    }

    &--select {
      option {
        font-size: var(--font-l);
      }
    }
  }

  .field__label-wrapper {
    font-weight: 700;
    font-size: var(--font-l);
    margin-bottom: var(--margin-s);
    color: var(--c-inverse);

    @include whenSmallerThan(l) {
      font-size: var(--font-s);
      font-weight: 700;
    }

    &--checkbox .field__label-content {
      font-size: var(--font-l);
      font-weight: normal;
      padding-inline-start: 0.5em;

      @include whenSmallerThan(l) {
        padding-inline-start: 0.6em;
      }
    }

    &--checkbox input:checked + .field__label-content::before {
      background-color: var(--c-alt);
      border-color: var(--c-alt);
      height: var(--margin-l);
      width: var(--margin-l);

      @include whenSmallerThan(l) {
      }
    }

    &--checkbox input:checked + .field__label-content::after {
      font-weight: 200;
      height: var(--margin-l);
      width: var(--margin-l);
      top: 0px;
      font-size: var(--font-m);

      @include whenSmallerThan(l) {
        top: 0px;
        inset-inline-end: calc(100% + 0.3em);
      }
    }

    &--radio .field__label-content {
      font-size: var(--font-l);
      font-weight: normal;
      padding-inline-start: 0.5em;

      @include whenSmallerThan(l) {
        padding-inline-start: 0.6em;
      }
    }

    &--radio input:checked + .field__label-content::before {
      // background-color: var(--c-alt);
      // border-color: var(--c-alt);
      border-color: var(--c-alt);
      height: var(--margin-l);
      width: var(--margin-l);

      @include whenSmallerThan(l) {
      }
    }

    &--radio input + .field__label-content::after {
      background-color: transparent !important;
      color: var(--c-alt);
    }

    &--radio input:checked + .field__label-content::after {
      content: " ";
      position: absolute;
      top: 1px;
      inset-inline-end: calc(100% + 0.25em + 1px);
      height: 1.25em;
      width: 1.25em;

      border-radius: var(--border-radius-round);
      background-color: var(--c-alt) !important;
      box-shadow: #{var(--box-shadow-1)} inset;
      transition: opacity 150ms ease;

      @include whenSmallerThan(l) {
        top: 0;
        inset-inline-end: calc(100% + 0.25em + 1px);
      }
    }
  }

  .field__icon--end {
    inset-inline-end: var(--margin-m);
  }

  .field__error {
    font-size: var(--font-l);
    margin-inline-start: var(--margin-l);
  }

  .multi-select-item-wrapper {
    align-items: center;
    height: 100%;
  }

  .select2-dropdown {
    border-radius: var(--margin-l);
    padding-inline: var(--margin-l);
    padding-block: calc(var(--margin-l) * 0.8);
    border: 1px solid var(--c-gray-2);

    .options-wrapper {
      padding: 0;
      margin: 0;
    }

    @include whenSmallerThan(l) {
      padding: var(--margin-m);
      border-radius: var(--margin-m);
    }

    .select2-dropdown-close--absolute {
      top: var(--margin-l);
      inset-inline-end: calc(var(--base-margin) * 34);

      @include whenSmallerThan(l) {
        top: var(--margin-m);
        inset-inline-end: var(--margin-m);
        border-radius: var(--margin-m);
      }
    }

    .select2-item-icon {
      font-size: var(--font-l);
      padding-inline-end: var(--margin-l);
    }
  }

  // add groups
  .add-group {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    border: 1px dashed var(--c-core-lead);
    color: var(--c-core-lead);
    font-size: var(--font-l);
    cursor: pointer;

    @include whenSmallerThan(m) {
      font-size: var(--font-s);
    }

    &__inner {
      z-index: var(--z-2);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: var(--margin-m);
      font-weight: 600;
      color: var(--c-lead);

      @include whenSmallerThan(m) {
        font-weight: normal;
      }
    }

    &--large {
      .add-group__inner {
        min-height: calc(var(--base-margin) * 130);

        @include whenSmallerThan(m) {
          min-height: calc(var(--base-margin) * 70);
        }
      }
    }

    &--with-bg {
      &::before {
        content: " ";
        position: absolute;
        top: 1px;
        inset-inline-start: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: var(--border-radius);
        z-index: var(z-1);
        background: rgba(149, 32, 211, 0.04);
        transition: background 0.3s ease;
      }
    }

    &:hover::before {
      background: rgba(149, 32, 211, 0.09);
    }
  }

  // 2per row fix
  .form__fieldset-content[class*="-per-row"].max-2-per-row > .field {
    flex: 0 0 calc(100% / 2 - (1) * var(--fieldset-field-gap) / 2 - 1px);

    & + .field {
      margin-top: 0;
      margin-bottom: 0;
    }

  }
  #{$notificationSelector} {
    &__title {
      font-size: calc(var(--font-l) * 1.2);
    }
  }

  //modal
  .modal {
    --modal-inline-padding: var(--margin-2xl);

    @include whenSmallerThan(m) {
      --modal-inline-padding: var(--margin-m);
    }

    .modal__inner {
      border-radius: var(--margin-l);

      @include whenSmallerThan(m) {
        border-radius: var(--margin-m);
      }
    }

    .modal__header {
      margin-inline: var(--modal-inline-padding);
    }

    .modal__title {
      text-align: center;
      padding-inline: var(
        --margin-l
      ); // so the centered title is actually centered
      color: var(--c-inverse);

      @include whenSmallerThan(m) {
        font-size: var(--font-xl);
        line-height: 1.2;
      }
    }

    .modal__body {
      padding-inline: var(--modal-inline-padding);
    }

    .modal__footer {
      margin-inline: var(--modal-inline-padding);
    }
  }

  .badge {
    font-size: calc(var(--font-m) * 0.9);
    font-weight: 500;
    padding: calc(var(--margin-s) * 0.8) var(--margin-m);

    &--s {
      font-size: calc(var(--font-m) * 0.7);
      font-weight: 500;
      padding: calc(var(--margin-s) * 0.6) var(--margin-s);

      .badge-content .span {
        position:relative;
        top: -1px;
      }
    }
  }

  .user-dashboard-layout {
    background: var(--c-bg);
    overflow-x: hidden;

    h1 {
      color: var(--c-inverse);
    }

    .content-area > p {
      max-width: calc(var(--base-margin) * 800);
    }
    .item-table-wrapper {
      // compensate for table margin
      margin-top: calc(var(--base-margin) * -1 * 20);

      > .width-expand.overflow-x-scroll {
        @media (min-width: 1024px) {
          overflow: visible !important;
        }
      }
    }

    table {
      border-collapse: separate;
      border-spacing: 0 18px;

      thead {
        background: transparent;
        color: var(--c-inverse);

        tr {
          &::after {
            display: none;
          }
        }
        th {
          background-color: var(--c-comp-alt-1);
          padding-block: calc(var(--base-margin) * 16) !important;

          &:first-child {
            border-radius: 0 25px 25px 0;
            padding-inline-start: calc(var(--base-margin) * 40);

            @media (max-width: 767px) {
              padding-inline-start: calc(var(--base-margin) * 20);
            }
          }

          &:last-child {
            border-radius: 25px 0 0 25px;
            padding-inline-end: calc(var(--base-margin) * 40);

            @media (max-width: 767px) {
              padding-inline-end: calc(var(--base-margin) * 20);
            }
          }
        }

        th.orderable:hover {
          background-color: var(--c-comp-alt-1);
          color: var(--c-inverse);
          font-weight: 900;
          opacity: 1;
        }
        th.is-current-ordering {
          color: var(--c-inverse);
          font-weight: bold;
        }
      }

      tbody tr {
        border-color: transparent;
        background: transparent;
        padding: 20px;
        margin-block: var(--margin-m);
        border-radius: calc(var(--base-margin) * 24);

        box-shadow: 0px 35.28px 70.559px 0px rgba(68, 68, 68, 0.1);
        &::after {
          display: none;
        }

        td {
          background: var(--c-bg);

          &:first-child {
            border-radius: 0 25px 25px 0;
          }

          &:last-child {
            border-radius: 25px 0 0 25px;
          }
        }
      }
      td {
        font-size: calc(var(--base-margin) * 17);
        font-weight: 500;
        color: var(--c-inverse);
      }
      .badge {
        min-width: calc(var(--base-margin) * 100) !important;
        border-radius: calc(var(--base-margin) * 25);

        @media (max-width: 767px) {
          min-width: calc(var(--base-margin) * 50);
        }
      }

      .row-actions {
        .button.button--slim {
          font-size: var(--font-m);
          max-width: calc(var(--base-margin) * 46);
          max-height: calc(var(--base-margin) * 46) !important;
          border-radius: calc(var(--base-margin) * 15);
          min-height: 0 !important;

          @media (max-width: 767px) {
            font-size: var(--font-m);
            max-width: calc(var(--base-margin) * 38);
            max-height: calc(var(--base-margin) * 38) !important;
            border-radius: calc(var(--base-margin) * 10);
          }
        }
      }
    }

    .side-menu-container {
      .nav-inner-wrapper {
        position: relative;
        background: var(--c-gray-1) !important;
        padding: 0 !important;
        border-top-left-radius: calc(var(--base-margin) * 40);
        border-bottom-left-radius: calc(var(--base-margin) * 40);

        &::after {
          content: " ";
          z-index: 2;
          position: absolute;
          top: 0;
          inset-inline-end: 100%;
          width: 400px;
          background: var(--c-gray-1);
          height: 100%;
        }

        @media (max-width: 1024px) {
          border-inline-end: none;
          overflow: visible;
        }
      }
      .side-nav {
      }
      .side-nav-item {
        font-size: calc(var(--base-margin) * 24);
        font-weight: 500;
        color: var(--c-gray-5);
        margin-bottom: var(--margin-m);

        &:first-of-type a {
          // match with menu without using overflow hidden
          border-top-left-radius: calc(var(--base-margin) * 40) !important;
        }
        i {
          position: relative;
          top: calc(var(--base-margin) * -0);
          font-size: calc(var(--base-margin) * 44);
          color: var(--c-lead);
        }

        & a {
          border-top-right-radius: var(--margin-l);
          border-bottom-right-radius: var(--margin-l);

          &:hover {
            background-color: transparent;
            color: var(--c-gray-5);
          }

          &.active.router-link-exact-active {
            background: var(--c-lead);
            color: var(--c-bg);

            i {
              font-size: calc(var(--base-margin) * 44);
              color: var(--c-gray);
            }

            &::before {
              display: none;
            }
          }
        }
      }

      .side-nav-item__inner {
        gap: var(--margin-l);
        display: flex;
        align-items: center;
      }
    }
  }

  .card {
    border-radius: var(--margin-l);
  }

  .saffron-tooltip {

    &__content {
      font-size: var(--font-m);
      font-weight:400;
      padding: var(--margin-m);
      border-radius: var(--border-radius);
    }

  }

  // chat

}

// 6th: other custom CSS
*,
*::before,
*::after {
  box-sizing: border-box !important;
}

// 6th: custom css
html,
body {
  background: var(--c-bg);
  font-size: var(--font-m);

  * {
    font-family: "Public Sans", "Assistant", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }

  &[lang="he"] * {
    font-family: "Rubik", "Assistant", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }

  &[lang="he"] .app-page *,
  .app-page * {
    font-family: "IBM Plex Sans Hebrew", sans-serif;
  }
}
